import React from 'react';
import { Link } from 'gatsby';

// import { Layout } from '../components/Layout/Layout';
import { TitleAndMetaTags } from '../components/TitleAndMetaTags/TitleAndMetaTags';
import { Section } from '../components/Section/Section';
import { Contact } from '../components/Contact/Contact';
import { FakeImg } from '../components/FakeImg/FakeImg';
import { Banner } from '../components/Banner/Banner';

import fullWidthImageRollingImg from '../images/full-width-image-rolling.jpg';
import storyImg from '../images/story.jpg';
import familyImg from '../images/family.jpg';

function StoryPage({ location }) {
	return (
		// <Layout location={location}>
		<div className="main">
			<TitleAndMetaTags title="Story" pathname="story" />
			<Section>
				<div className="grid">
					<h1 className="col -block md-push-1 lg-push-2 title">
						This is our story.
						<br />
						<span className="salmon">A family adventure.</span>
					</h1>
				</div>
				<div className="grid">
					<div className="col fluid md-7 md-push-1 lg-6 lg-push-2">
						<FakeImg className="-square" img={storyImg} />
					</div>
				</div>
				<div className="grid">
					<div className="col md-7 lg-5 md-push-1 lg-push-3">
						<h2>
							JK's secret<sup className="-yellow">&#9679;</sup>
						</h2>
					</div>

					<div className="col md-7 lg-5 md-push-1 lg-push-3">
						<p className="measure">
							Bestfoods has been trading for 30 years. It started as a family shop by
							Jeyam and Kanen with one small rented shop in Croydon has over 24 franchised
							shops in London and Home counties. Team sourced fresh vegetables from Sri
							Lanka and imported Rice, Rice Flour and Jaffna curry powder as lead products
							from India to satisfy the Sri Lankan diaspora. Gradually shop became very
							popular within the Tamils and customers stared driving from faraway. On the
							request of many customers Bestfoods started the franchise operation close to
							the customers.
						</p>
						<p>
							<Link to="/ingredients" className="button">
								Our range
							</Link>
						</p>
					</div>

					<div className="col md-3 lg-2">
						<p className="small faded footnote -yellow" data-id="&#9679;">
							authentic foods.
						</p>
					</div>
				</div>
			</Section>

			<Banner img={fullWidthImageRollingImg} mobileImg={fullWidthImageRollingImg} />

			<Section className="-white">
				<div className="grid">
					<div className="col md-push-1 lg-push-3">
						<h2>Family business</h2>
					</div>
				</div>

				<div className="grid">
					<div className="col md-7 lg-5 md-push-1 lg-push-3">
						<p className="measure">
							Rita is already renowned for her Pão de Queijo with her family and friends.
						</p>

						<p className="measure">
							And none more so than with Clara, her son Pedro’s girlfriend. Allergic to
							gluten, Pão de Queijo has quickly become a staple in Clara’s diet, and she
							continues to be in awe of the tasty and fluffy gluten free snacks.
						</p>

						<p className="measure">
							A truly family enthused adventure, Rita’s dream is for Londoners to try her
							delicious Brazilian bites, and with the help of Clara and Pedro, to share
							them with everyone in town.
						</p>
					</div>

					<div className="col fluid md-7 md-push-1 lg-6 lg-push-2">
						<FakeImg className="-three-x-two" img={familyImg} />
					</div>
					<div className="col fluid md-7 md-push-1 lg-6 lg-push-2 small faded">
						Family photo by <a href="https://munchies.com">Munchies</a>.
					</div>
				</div>
			</Section>

			<Contact />
		</div>
		// </Layout>
	);
}

export default StoryPage;
